import './FontsPage.sass'
import {useEffect, useRef, useState} from "react";
import {FontLoadingSplash} from "../components/fonts/FontLoadingSplash";
import {FontSelector} from "../components/fonts/FontSelector";
import {TextFormatters} from "../components/fonts/TextFormatters";
import {TextAligners} from "../components/fonts/TextAligners";
import ReactSlider from "react-slider";
import {Button} from "../components/Button";
import html2canvas from "html2canvas";
import {useTranslation} from "react-i18next";
import {TextResizer} from "../components/fonts/TextResizer";


export function FontsPage() {

    const [theme, setTheme] = useState("light");
    const isLight = theme !== 'dark'

    const {t} = useTranslation('root', {keyPrefix: 'fonts'});

    const [selectedFont, setSelectedFont] = useState('Roboto')

    const [fontSpacing, setFontSpacing] = useState(1)
    const [italic, setItalic] = useState(false)
    const [bold, setBold] = useState(false)
    const [align, setAlign] = useState('center')
    const [fontSizeK, setFontSizeK] = useState(1)

    const showcaseEl = useRef(null)
    const hiddenAEl = useRef(null)
    const hiddenCanvasWrapper = useRef(null)
    const textSpanEl = useRef(null)

    const initialFont = window.innerWidth < 900 ? 30 : 70

    const [customFonts, setCustomFonts] = useState([])

    const onCustomFontsFetched = (fonts) => {
        setCustomFonts(fonts)
    }

    const fsz = Math.floor(initialFont * fontSizeK)

    const style = {
        fontSize: `${fsz}pt`,
        fontFamily: selectedFont,
        fontWeight: bold ? 'bold' : 'normal',
        fontStyle: italic ? 'italic' : 'normal',
        textAlign: align,
    }
    const [textDdK, setTextDdK] = useState(null)

    const setSizeDropDownToK = (x) => {
        setTextDdK(Math.floor(initialFont*x))
    };
    return <div className={`FontsPage ${theme}`} dir={'ltr'}>
        <div className={'container'}>
            <div className={'centrifier'}>
                <div className={'row main px-2'}>
                    <FontLoadingSplash onCustomFontsFetched={onCustomFontsFetched}/>
                    <div style={{'width': 'calc(100% - 50px)', flex: '0 0 auto'}}>
                        <div className={'row justify-content-center'}>
                            <div className={'col-12 col-lg'}>
                                <div className={'font-selector'}>
                                    <FontSelector
                                        value={selectedFont}
                                        customFonts={customFonts}
                                        onChange={(v) => setSelectedFont(v)}
                                        theme={theme}/>
                                </div>
                            </div>
                            <div className={'col-auto '}>
                                <div className={'font-formatting'}>
                                    <TextFormatters bold={bold} italic={italic} setValue={(b, i) => {
                                        setItalic(i)
                                        setBold(b)
                                    }}/>
                                    <TextAligners value={align} setValue={x => setAlign(x)}/>
                                </div>
                            </div>
                            <div className={'col-auto '}>
                                <div className={'font-formatting'}>
                                    <TextResizer
                                        sliderValue={textDdK}
                                        value={fontSizeK}
                                        setValue={x => {
                                            setFontSizeK(
                                                x / initialFont
                                            )
                                        }} theme={theme}/>
                                    <div className={'icon-group'}>
                                        <div data-active={isLight} onClick={() => setTheme(isLight ? 'dark' : 'light')}>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 512 512">
                                                <path fill={isLight ? 'black' : 'white'}
                                                      d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'col-lg-12 col-12'}>
                                <div className={'showcase'} ref={showcaseEl}>
                        <span
                            ref={textSpanEl}
                            style={{
                                ...style,
                                'display': 'flex',
                                'overflow': 'hidden',
                                'alignItems': 'center',
                                'justifyContent': align,
                                'wordBreak': 'normal',
                                'width': '100%',
                                'resize': 'none',
                                'maxWidth': '85vw',
                                'border': 'none',
                                'outline': 'none',
                                'color': isLight ? 'black' : 'white',
                                'fontFamily': selectedFont, // 'backgroundColor': isLight?'white':'black',
                                'letterSpacing': fontSpacing + 'px',
                                'transition': 'background-color 0.5s ease'

                            }} spellCheck={false}

                            onClick={x => console.log(x)}
                            suppressContentEditableWarning={true}
                            contentEditable={true}
                        >An awesome tattoo to be here</span>
                                </div>
                            </div>
                            <div className={'col-lg-12 col-12'}>
                                <div className={'font-width-selector'}>
                                    <ReactSlider
                                        className="h-font-width-slider"
                                        thumbClassName="thumb"
                                        trackClassName="track"
                                        renderThumb={(props, state) => <div {...props}></div>}
                                        orientation={'horizontal'}
                                        step={0.1}
                                        min={0}
                                        max={25}
                                        defaultValue={1}
                                        onChange={x => setFontSpacing(x)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-auto '} id={'verticalBlock'}>
                        <div className={'font-size-selector'}>

                            <ReactSlider
                                className="v-fonts-slider"
                                thumbClassName="thumb"
                                trackClassName="track"
                                renderThumb={(props, state) => <div {...props}></div>}
                                orientation={'vertical'}
                                step={0.001}
                                min={0.15}
                                max={2}
                                defaultValue={1}
                                invert
                                value={fontSizeK}

                                onChange={x =>{
                                    setSizeDropDownToK(x)
                                    setFontSizeK(x)}

                                }
                            />
                        </div>
                    </div>

                </div>
                <div className={'row justify-content-center mt-3'}>
                    <div className={'col-auto'}>
                        <a ref={hiddenAEl} style={{'display': "none"}}>
                            that is a fake hidden link</a>
                        <Button onClick={async () => {

                            if (!isLight) showcaseEl.current.style.backgroundColor = 'black'

                            const canvas = await html2canvas(showcaseEl.current)

                            hiddenAEl.current.setAttribute('download', 'tattoo.png');
                            hiddenAEl.current.setAttribute('href', canvas.toDataURL("image/png")
                                .replace("image/png", "image/octet-stream"));
                            hiddenAEl.current.click();

                            if (!isLight) showcaseEl.current.style.backgroundColor = 'unset'


                        }}>{t('save')}</Button>
                    </div>
                    <div ref={hiddenCanvasWrapper}></div>
                </div>
            </div>
        </div>
    </div>
}

