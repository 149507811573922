import {useContext, useEffect, useState} from "react";
import {PortfolioModalContext} from "../App";
import api from "../api";
import chevronDown from "../img/icons/chevron-dd.svg";
import {PolysizeGallery} from "./Gallery";

const designToImg = (res,showAvatar) => {
    return ({
        guid: res.guid,
        src: api.resToUrl(res.images[0].guid, 'lg'),
        width: res.images[0].lgSize.width,
        height: res.images[0].lgSize.height,
        artistImgSrc: showAvatar && res.artist?.avatarGuid  ? api.resToUrl(res.artist?.avatarGuid,'sm'):null,
        artistId:  showAvatar && res.artistGuid,

    })
}

export const DesignsGallery = ({artistGuid, showAvatar}) => {
    const [designs, setDesigns] = useState([])
    const [offset, setOffset] = useState(0)
    const portfolioModalContext = useContext(PortfolioModalContext)

    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        api.designs.list(artistGuid, offset)
            .then(x => {
                if (x.length === 0)
                    setShowLoader(false)
                setDesigns(p => [...p, x])
                if (x.length < 30)
                    setShowLoader(false)
            })

    }, [artistGuid, offset])

    return <div className={"DesignsGallery"}>
        {designs.map(i => <PolysizeGallery images={i.map(x => ({
            ...designToImg(x,showAvatar),
            onClick: () => {
                portfolioModalContext.setModalPortfolios([x.images[0]])
            }
        }))}/>)}

        {showLoader ?
            <div className={"load-next"} onClick={() => setOffset(offset + 15)}>
                <img src={chevronDown} alt={"arrow down"}/>
            </div> : <></>}
    </div>;
};