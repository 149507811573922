export const TextAligners = ({value, setValue}) => {
    return <div className={'icon-group'}>
        <div data-active={value === 'left'} onClick={() => setValue('left')}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path fill={value === 'left' ? 'black' : 'white'}
                      d="M120-120v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Zm0-160v-80h480v80H120Zm0-160v-80h720v80H120Z"/>
            </svg>
        </div>
        <div data-active={value === 'center'} onClick={() => setValue('center')}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path fill={value === 'center' ? 'black' : 'white'}
                      d="M120-120v-80h720v80H120Zm160-160v-80h400v80H280ZM120-440v-80h720v80H120Zm160-160v-80h400v80H280ZM120-760v-80h720v80H120Z"/>
            </svg>
        </div>
        <div data-active={value === 'right'} onClick={() => setValue('right')}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                <path fill={value === 'right' ? 'black' : 'white'}
                      d="M120-760v-80h720v80H120Zm240 160v-80h480v80H360ZM120-440v-80h720v80H120Zm240 160v-80h480v80H360ZM120-120v-80h720v80H120Z"/>
            </svg>
        </div>
    </div>
}
