import {useContext, useEffect, useState} from "react";
import api from "../../api";
import {getBrowserLocale} from "../../l18n/init";
import './ShopPage.sass'
import {CategoryBlock} from "./CategoryBlock";
import {CategoriesRow} from "./CategoriesRow";
import {CartBtn} from "./CartBtn";
import {GoodModalContext, ShopCartContext} from "../../App";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

const goodPerCategory = 6

export const ShopPage = () => {
    const {goodId} = useParams()
    const {t} = useTranslation('root', {keyPrefix: 'shop'});
    const {cartGoods} = useContext(ShopCartContext)
    const lang = getBrowserLocale()
    const [categories, setCategories] = useState([])
    const {setGood} = useContext(GoodModalContext)

    const [goodsDict, setGoodsDict] = useState({})
    useEffect(() => {
        api.shop.listCategories().then(categories => {
            setCategories(categories)

            for (let cat of categories)
                api.shop.listGoods(cat.key).then(x => setGoodsDict(v => ({
                    ...v,
                    [cat.guid]: x.slice(0, goodPerCategory)
                })))
        })
    }, []);
    useEffect(() => {
        if (!goodId)
            return
        api.shop.getGood(goodId).then(good => setGood(good))

    }, [goodId]);

    return <div className={'ShopPage container'}>
        <h3>{t('merch')}</h3>
        <CategoriesRow categories={categories} lang={lang}/>

        {categories.filter(cat => goodsDict[cat.guid]).map(cat => {
            return <CategoryBlock
                key={'categoryBlock' + cat.guid} category={cat} items={goodsDict[cat.guid] ?? []}
                lang={lang}
            />
        })}
        <CartBtn cartGoods={cartGoods}/>
    </div>
}


