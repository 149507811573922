import {useTranslation} from "react-i18next";
import './HealingPage.sass'
import {getBrowserLocale} from "../l18n/init";

export const HealingPage = () => {
    const {t} = useTranslation('root', {keyPrefix: 'healing'});

    return <div className={'container HealingPage'} dir={getBrowserLocale()==='he' ? 'rtl' : 'ltr'}>
        <div className={'row px-3 justify-content-center'}>
            <div className={'col-lg-9 col'}>
                <h3>{t('title')}</h3>
                <p>{t('p1')}</p>
                <p><span className={'highlight'}>{t('sh1')}</span> {t('p2')}</p>
                <h5><span>🚫</span>{t('h1')}</h5>
                <ul className={'do-not-do-list'}>
                    <li><span>☝️</span> {t('i1.a')}</li>
                    <li><span>☝️</span> {t('i1.b')}</li>
                    <li><span>☝️</span> {t('i1.c')}</li>
                    <li><span>☝️</span> {t('i1.d')}</li>
                    <li><span>☝️</span> {t('i1.e')}</li>
                </ul>
                <p>{t('p3')}</p>
                <h5>❤️‍🩹 {t('h2')}</h5>
                <ol>
                    <li><span>{t('i2.a.a1')}</span>{t('i2.a.a2')}</li>
                    <li><span>{t('i2.b.b1')}</span>{t('i2.b.b2')}</li>
                    <li><span>{t('i2.c.c1')}</span>{t('i2.c.c2')}</li>
                </ol>
            </div>
        </div>
    </div>


}