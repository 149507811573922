import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import rootLocales from './locales/init';

export const getBrowserLocale = () => {
    const result = (()=>{
        const lng = localStorage.getItem('bi-lang')
        if (!lng) {
            const userLang = navigator.language || navigator.userLanguage;
            if (userLang.includes('he'))
                return 'he'
            if (userLang.includes('ru'))
                return 'ru'
            return 'en'
        }
        return lng
    })()

    setBrowserLocale(result)
    return result

}

export const setBrowserLocale = (lng) => {
    localStorage.setItem('bi-lang', lng)

    window.document.querySelector('html').setAttribute('lang', lng)
    window.document.querySelector('html').setAttribute('dir', lng === 'he' ? 'rtl' : 'ltr');
}

i18n.use(initReactI18next).init({
    lng: getBrowserLocale(),
    fallbackLng: 'en',
    resources:rootLocales,
    // debug:true,
    // defaultNS:'root',
    nsSeparator:'.',
    interpolation: {
        escapeValue: false,
    },
})

