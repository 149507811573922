import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useContext, useEffect, useState} from "react";
import {GoodModalContext, ShopCartContext} from "../../App";
import {getBrowserLocale} from "../../l18n/init";
import api from "../../api";
import {CategoriesRow} from "./CategoriesRow";
import {CategoryBlock} from "./CategoryBlock";
import {CartBtn} from "./CartBtn";

export const CategoryPage = () => {
    const {catId, goodId} = useParams()
    const {t} = useTranslation('root', {keyPrefix: 'shop'});
    const {cartGoods} = useContext(ShopCartContext)
    const lang = getBrowserLocale()
    const [categories, setCategories] = useState([])
    const {setGood} = useContext(GoodModalContext)
    const [cat, setCat] = useState(null)
    const [goods, setGoods] = useState([])

    useEffect(() => {
        api.shop.listCategories().then(categories => {
            setCategories(categories)
        })
    }, []);

    useEffect(() => {
        api.shop.getCategoryWithGoods(catId).then(c => {
            setCat(c.category);
            setGoods(c.goods)
        })

    }, [catId]);

    useEffect(() => {
        if (!goodId)
            return
        api.shop.getGood(goodId).then(good => setGood(good))

    }, [goodId]);

    if (!cat || goods.length === 0)
        return <></>

    return <div className={'ShopPage container'}>
        <h3>{t('merch')}</h3>
        <CategoriesRow categories={categories} lang={lang} active={cat.guid}/>

        <CategoryBlock
            key={'categoryBlock' + cat.guid} category={cat} items={goods}
            lang={lang}
        />

        <CartBtn cartGoods={cartGoods}/>
    </div>
}