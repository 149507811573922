import {useNavigate} from "react-router-dom";
import './CategoriesRow.sass'

export const CategoriesRow = ({categories, lang,active=null}) => {
    const nav = useNavigate()
    return <div className={'row justify-content-around CategoriesRow'}>

        {categories.map(cat => <div className={'col-auto'}>
            <span key={'categoryRow-' + cat.guid} className={active===cat.guid ? 'active':''}
                  onClick={() => nav(`/shop/category/${cat.key}`)}>{cat.translations[lang]}</span>
            </div>
        )}
    </div>
}