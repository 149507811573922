import * as React from "react";
import {PhotoAlbum} from "react-photo-album";
import {useNavigate} from "react-router-dom";


const PhotoFrame = React.memo(
    React.forwardRef(function PhotoFrame(props, ref) {
        const nav = useNavigate()
        const {layoutOptions, imageProps, overlay, attributes} = props;
        const {alt, style, ...restImageProps} = imageProps;

        return (
            <div
                ref={ref}
                style={{
                    position: "relative",
                    width: overlay ? `calc(100% - ${2 * layoutOptions.padding}px)` : style.width,
                    padding: 1,
                    cursor:"pointer",
                    marginBottom: style.marginBottom
                }}
                className={`photo-frame`}
                onClick={props.photo?.onClick ?? (() => {
                })}
                {...attributes}
            >
                <img
                    alt={alt}
                    style={{
                        ...style,
                        width: "100%",
                        height: "auto",
                        padding: 0,
                        marginBottom: 0,
                    }}
                    {...restImageProps}
                />
                {props.photo.artistImgSrc
                    && <img alt={'artist pic'} className={'artist'}
                            onClick={(e) => {
                                e.stopPropagation()
                                nav('/artist/' + props.photo.artistId)
                            }
                            }
                            src={props.photo.artistImgSrc}/>}


            </div>
        );
    }),
);


export const PolysizeGallery = ({images, onClick}) => {
    const photos = (images).map((photo) => ({
        ...photo,
        id: photo.guid || photo.src,
    }))


    const renderPhoto = (props) => {
        return <PhotoFrame {...props}/>
    };

    return (
        <PhotoAlbum
            photos={photos}
            targetRowHeight={(containerWidth) => {
                if (containerWidth < 400) return 180;
                if (containerWidth < 900) return 320;
                if (containerWidth < 1200) return 400;
                return 500;
            }}
            layout="rows"
            spacing={0}
            renderPhoto={renderPhoto}/>
    );
}
