import flagRu from "../img/flags/ru.webp";
import flagEn from "../img/flags/en.webp";
import flagHe from "../img/flags/he.webp";
import chevronDown from "../img/icons/chevron-d.svg";
import './LangSelector.sass'

import i18n from "i18next";
import {getBrowserLocale, setBrowserLocale} from "../l18n/init";
import {useState} from "react";


const langToFlag = {
    'he': flagHe,
    'en': flagEn,
    'ru': flagRu,
}

export const LangSelector = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [selectedLang, setSelectedLang] = useState(getBrowserLocale());

    const handleLangChange = (e) => {
        const l = e.toLowerCase()

        let subdomain = window.location.host.split('.')[0].toLowerCase()
        if (['en', 'ru', 'he'].includes(subdomain)) {
            setBrowserLocale(l)
            window.location.href = window.location.href.replace(subdomain + '.', '');
            return
        }

        i18n.changeLanguage(l)
        setBrowserLocale(l)
        setIsOpen(false)
        setSelectedLang(e)
    }

    return <span className={"LangSelector"}>
        <div onClick={() => setIsOpen(v => !v)}>
            <img src={langToFlag[selectedLang]} alt={selectedLang} className={'flag'}/>
            <span>{selectedLang.toUpperCase()} <img src={chevronDown} alt={''}/></span>
        </div>
        <ul className={`lang-dd ${isOpen ? 'open' : ''}`}>
            {Object.entries(langToFlag)
                .map(([k, v]) => <li
                        key={`langdditem${k}`} onClick={() => handleLangChange(k)}>
                        <img src={v} alt={k} className={'flag'}/> {k.toUpperCase()}
                    </li>
                )}
        </ul>
    </span>
};