import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

export function MobileHeader({target, onClick}) {
    const [showDropdown, setShowDropdown] = useState(false)
    const onClickAnywhere = () => setShowDropdown(false)
    const {t} = useTranslation('root',{keyPrefix: 'header'})

    useEffect(() => {
        window.addEventListener('click', onClickAnywhere)
        return () => window.removeEventListener('click', onClickAnywhere)
    },[])

    const options = {
        'welcome': t('options.welcome'),
        'artists': t('options.artists'),
        'portfolio': t('options.portfolio'),
        'footer': t('options.footer'),
        'healing': t('options.healing'),
        'fonts':t('options.fonts'),
        'shop':t('options.shop')
    }

    return <div className={'show-mobile'}>
        <span className={'mobile-nav active'} onClick={(e) => {
            e.stopPropagation()
            setShowDropdown(true);
        }}>{options[target]}</span>
        <ul className={`dropdown centered ${showDropdown ? 'show' : ''}`}>
            {Object.entries(options).map(([k, v], i) =>
                <li key={'mobHeaderLi' + i} onClick={() => onClick(k)}>{v}</li>)}
        </ul>
    </div>
}