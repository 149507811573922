import './GoodCard.sass'
import api from '../../api'
import {getBrowserLocale} from "../../l18n/init";
import {useContext, useEffect} from "react";
import {GoodModalContext} from "../../App";

const getQty = (good) => {
    return good.qty ?? Object.entries(good.sizesQty).map(x => x[1]).reduce((a, b) => a + b)
}
export const GoodCard = ({good, className}) => {
    const lang = getBrowserLocale()
    const {setGood} = useContext(GoodModalContext)


    return <div className={`GoodCard ${className}`}>
        <div className={'goodContent'} onClick={() => {
            setGood(good)
            window.history.replaceState(null, `Bad Idea Shop - ${good.title}`, window.location.pathname + '/' + good.guid)
        }
        }>
            <div className={'content-wrapper'}>
                <img src={api.resToUrl(good.photosIds[0], 'sm')} alt={'product img'} className={'productImage'}/>
                <div className={'row justify-content-between labelsRow'}>
                    <span className={'category col'}>{good.category.translations[lang]}</span>
                    <span className={'qty col-auto'}>В наличии: {getQty(good)}</span>
                </div>
                <div className={'row justify-content-between mainRow '}>
                    <span className={'name col pe-3'}>{good.title}</span>
                    <span className={'price col-auto  mt-3'}> {good.price} ₪</span>
                </div>
            </div>
        </div>
    </div>
}