import chevronDown from "../img/icons/chevron-dd.svg";
import './PortfolioGallery.sass'
import {Portfolio} from "./section/Portfolio";
import {useContext, useEffect, useState} from "react";
import api from "../api";
import {PortfolioModalContext} from "../App";

export const PortfolioGallery = ({artistGuid,showAvatar}) => {
    const [portfolios, setPortfolios] = useState([])
    const [offset, setOffset] = useState(0)
    const portfolioModalContext = useContext(PortfolioModalContext)

    const [showLoader, setShowLoader] = useState(true)

    useEffect(() => {
        api.portfolios.list(artistGuid, offset)
            .then(x => {
                setPortfolios(p => [...p, ...x])

                if (x.length === 0)
                    setShowLoader(false)
                else if(x.length< 15)
                    setShowLoader(false)
                else if(x.length === 15)
                    api.portfolios.list(artistGuid, offset+15).then(xx=>{
                        if(xx.length === 0)
                            setShowLoader(false)
                    })

            })

    }, [artistGuid, offset])

    return <div className={"PortfolioGallery"}>
        {portfolios.map(p=>
            <Portfolio
                resGuid={p.photos[0].guid} key={p.guid}
                artist={p.artist} showAvatar={showAvatar}
                onPhotoClick={()=>portfolioModalContext.setModalPortfolios(p.photos)}

            />)}
        {showLoader ?
            <div className={"load-next"} onClick={() => setOffset(offset + 15)}>
                <img src={chevronDown} alt={"arrow down"}/>
            </div> : <></>}
    </div>;
};
