import React from 'react'

export function BusinessDays({bars}) {

    const to = ([
        ...new Set(Object.entries(bars)
            .map(([, v]) => v)
            .filter(x => Boolean(x))
            .map(x => x[1])
        )
    ]).sort((a,b)=>a-b).reverse()

    const from = ([
        ...new Set(Object.entries(bars)
            .map(([, v]) => v)
            .filter(x => Boolean(x))
            .map(x => x[0])
        )
    ]).sort((a,b)=>a-b)


    return <div style={{
        display: 'grid',
        gridTemplateColumns: `repeat( ${Object.entries(bars).length}, 47px  ) 30px`,
        gridTemplateRows: `40px repeat(${to.length} , 30px) 60px repeat(${from.length} , 30px)`
    }}>
        {Object.entries(bars).map(([k, v], i) => {
                return <React.Fragment key={'bar'+i}>
                    <div style={{color: 'white', textAlign: 'center', verticalAlign: 'middle', gridRow: '1'}}>
                        <b style={{'fontSize':24}}>{k}</b>
                    </div>
                    {v ?
                        <div style={{
                            gridRow: `${2 + to.indexOf(v[1])} / ${-1 - (from.indexOf(v[0]))}`,
                            gridColumn: i + 1,
                            padding: '5px',
                            display: 'flex', justifyContent: 'center', alignItems: 'center'
                        }}>
                            <div style={{background: '#963d9a', height: '100%', width: '4px',borderRadius:5}}>

                            </div>
                        </div>
                        :
                        <div style={{
                            gridRow: `2 / -1`,
                            gridColumn: i + 1,
                            padding: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}>
                            <div style={{background: "gray", height: "10px", width: "10px", borderRadius: 5}}/>

                        </div>
                    }

                </React.Fragment>
            }
        )}
        {to.map((t, i) =>
            <div key={`to_${i}`} style={{
                gridRow: i + 2,
                gridColumn: '-2',
                color: "white",
                fontSize:22
            }}>{t}</div>
        )}
        {from.map((f, i) =>
            <div key={`from_${i}`} style={{
                gridRow: 0 - (i + 2),
                gridColumn: '-2',
                color: "white",
                fontSize:22

            }}>{f}</div>
        )}


    </div>
}