import {useEffect, useState} from "react";
import api from "../api";
import chevron from "../img/icons/chevron-dd-gray.svg";
import './LightboxModal.sass'

let dragBegin;

export const LightboxModal = ({onBackdropClick, imageGuids, footer = <></>, imageSize = 'lg', className = '',imageBg=null}) => {
    const [activeIndex, setActiveIndex] = useState(0)
    const clamp = (i) => Math.min(Math.max(i, 0), imageGuids.length - 1);
    const hasFooter = Boolean(footer.type !== (<></>).type)

    useEffect(() => {
        setActiveIndex(0)
    }, [imageGuids]);

    if(!imageGuids?.length)
        return <></>
    return <div className={`LightboxModal ${className}`}>
        <div className={'backdrop'} onClick={onBackdropClick}>
            <div className={'container'}>
                <div className={'row flex-column justify-content-center'}
                     style={{height: hasFooter ? '100%' : 'unset', flexWrap: "nowrap"}}>
                    <div style={{flexGrow: 1,}} className={'d-flex flex-column justify-content-center'}
                         onClick={onBackdropClick}>

                        <div className={`col-auto d-flex justify-content-around ${hasFooter ? 'mt-auto' : ''}`}
                             style={{width: '100vw'}}>
                            <div className={'d-flex flex-column justify-content-center'}
                                 onClick={e=>e.stopPropagation()}
                            >
                                <div className={`arrow left ${imageGuids.length === 1 ? 'd-none':''}`} onClick={() => setActiveIndex(i => clamp(i - 1))}>
                                    <img src={chevron} alt={'left'}/>
                                </div>
                            </div>
                            <div style={{position: 'relative'}}
                                 onClick={e=>e.stopPropagation()}>
                                <img src={api.resToUrl(imageGuids[0], 'lg')}
                                     alt={''} className={`pic-fake pic ${imageSize}`}
                                />
                                {
                                    imageGuids.map((p, i) => {
                                        const di = activeIndex - i

                                        const cl = {
                                            [-2]: 'rightest',
                                            [-1]: 'righter',
                                            0: '',
                                            1: 'lefter',
                                            2: 'leftest',
                                        }[di] ?? (di > 0 ? 'leftest' : 'rightest');

                                        return <img
                                            src={api.resToUrl(p, 'lg')}
                                            alt={'tattoo'} className={`pic ${cl}  ${imageSize}`}
                                            style={{
                                                zIndex: 1000 - Math.abs(di),
                                                display: Math.abs(di) > 2 ? 'none' : 'inline',
                                                background:imageBg
                                            }}
                                            key={'imgmodal' + p}

                                            onTouchStart={di === 0 ?
                                                (event) => {
                                                    event.stopPropagation()
                                                    const t = event.changedTouches[0]
                                                    dragBegin = {x: t.clientX, y: t.clientY}
                                                    event.target.style.transition = `none`
                                                }

                                                : undefined}

                                            onTouchMove={di === 0
                                                ? (event) => {
                                                    event.stopPropagation()
                                                    const t = event.changedTouches[0]
                                                    const dx = t.clientX - dragBegin.x
                                                    const dy = t.clientY - dragBegin.y

                                                    event.target.style.transform = `translate(${dx}px , ${dy}px)`

                                                }
                                                : undefined}
                                            onTouchEnd={di === 0
                                                ? (event) => {
                                                    event.target.style.transition = null
                                                    event.target.style.transform = null
                                                    const t = event.changedTouches[0]

                                                    const dx = t.clientX - dragBegin.x


                                                    if (dx > 150)
                                                        setActiveIndex(i => clamp(i - 1))
                                                    if (dx < -150)
                                                        setActiveIndex(i => clamp(i + 1))
                                                }
                                                : undefined}
                                        />

                                    })
                                }
                            </div>
                            <div className={'d-flex flex-column justify-content-center'}
                                 onClick={e=>e.stopPropagation()}>
                                <div className={`arrow right ${imageGuids.length === 1 ? 'd-none':''}`} onClick={() => setActiveIndex(i => clamp(i + 1))}>
                                    <img src={chevron} alt={'right'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{flexGrow: 0}}>
                        <div className={'mb-lg-2'}>
                            {footer}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}