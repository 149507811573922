import {WelcomeSection} from "../components/section/WelcomeSection";
import {ArtistsSection} from "../components/section/ArtistsSection";
import {PortfoliosSection} from "../components/section/PortfoliosSection";
import {FooterSection} from "../components/section/FooterSection";

export const IndexPage=()=><>
    <WelcomeSection/>
    <ArtistsSection/>
    <PortfoliosSection artistGuid={'*'}/>
    <FooterSection/>
</>


