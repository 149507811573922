import {useNavigate} from "react-router-dom";

import './CategoryBlock.sass'
import {GoodCard} from "./GoodCard";

export const CategoryBlock = ({items, category, lang}) => {
    const nav = useNavigate()
    return <div className={'CategoryBlock'}>
        <span className={'categoryHeader'}
              onClick={() => nav(`/shop/category/${category.key}`)}>{category.translations[lang]}</span>

        <div className={'row   align-items-stretch'}>
            {[...items].map(item => {
                return <GoodCard good={item}  key={`goodCart-`+item.guid} className={'col-12 col-md-6 col-lg-4 ' }/>

            })}
        </div>
    </div>
}