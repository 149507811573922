import {LightboxModal} from "../../components/LightboxModal";
import {useContext, useEffect, useState} from "react";
import {GoodModalContext, ShopCartContext} from "../../App";

import './GoodModal.sass'

import {useTranslation} from "react-i18next";
import {Button} from "../../components/Button";
import {QtyControl} from "./QtyControl";

export const SizesSelector = ({sizes, onSelect, selected, className = ''}) => {

    return <div className={'SizesSelector ' + className}>
        {Object.entries(sizes).map(([size, qty]) => <span
            key={'sz-selector-' + size}
            onClick={(e) => {
                e.stopPropagation()
                return qty === 0 ? false : onSelect(size);
            }}
            className={`${qty === 0 ? 'disabled' : ''} ${selected === size ? 'selected' : ''}`}>
            {size.toUpperCase()}: <span className={'badge'}>{qty}</span>
        </span>)}
    </div>

}

const AddToCartControl = ({onChange}) => {
    const {t} = useTranslation('root', {keyPrefix: 'shop'});

    return <div className={'AddToCartControl'}>
        <Button outline={false} onClick={(e) => {
            e.stopPropagation()
            onChange(1);
        }}>{t('addToCart')}</Button>
    </div>

};


export const GoodModal = (props) => {

    const {good, setGood} = useContext(GoodModalContext)
    const {cartGoods, setCartGoods} = useContext(ShopCartContext)
    const [selectedSize, setSelectedSize] = useState(null)
    const [shakeSizes, setShakeSizes] = useState(false)
    useEffect(() => {
        setSelectedSize(null)
    }, [good]);

    const onCartChange = (qty) => {
        if (!selectedSize && good.sizesQty) {
            setShakeSizes(true)
            setTimeout(() => setShakeSizes(false), 500)
            return
        }
        let actualCart = JSON.parse(localStorage.getItem("cart") ?? '[]'); //load changes from another tabs
        const existing = actualCart.find(item => item.good.guid === good.guid && item.size === selectedSize)
        if (!existing) {
            actualCart.push({good: good, qty: 1, size: selectedSize})
        } else {
            existing.qty += qty
            if (existing.qty === 0)
                actualCart = actualCart.filter(item => item.qty > 0)
        }
        setCartGoods(actualCart)
    }
    if (!good)
        return <></>

    const cartPosition = cartGoods?.find(g => g.good.guid === good.guid && g.size === selectedSize)
    const maxQty = good.sizesQty?.[selectedSize] ?? good.qty;

    return <>
        <LightboxModal
            imageBg={''}
            className={'GoodModal'} onBackdropClick={() => {
                let pn =  window.location.pathname.split('/');
                pn.splice(pn.length - 1, 1)

                window.history.replaceState(null, `Bad Idea Shop - ${good.title}`,pn.join('/'))
                setGood(null)}
        } imageGuids={good.photosIds}
            imageSize={'sm'}
            footer={<div className={'footer'}>
                <div className={'row justify-content-center'} >
                    <div className={'col-8'} onClick={event => event.stopPropagation()}>
                        <h3>{good.title}</h3>
                        <p>{good.description}</p>
                    </div>
                </div>
                <div className={'row justify-content-center my-3'}>
                    <div className={'col-auto'} onClick={event => event.stopPropagation()}>
                        {
                            good.sizesQty ?
                                <SizesSelector className={shakeSizes ? 'shake' : ''} sizes={good.sizesQty}
                                               onSelect={setSelectedSize}
                                               selected={selectedSize}/>
                                : <span className={'qty'}>В наличии: <b>{good.qty}</b></span>
                        }
                    </div>
                </div>
                <div className={'row justify-content-center mb-3'}>
                    <div className={'col-auto'}>
                        {cartPosition ?
                            <QtyControl value={cartPosition.qty}
                                        size={selectedSize} max={maxQty}
                                        onChange={onCartChange}
                           />
                            :
                            <AddToCartControl
                                goodId={good.guid}
                                onChange={onCartChange}/>}
                    </div>
                </div>
            </div>
        }/>

    </>
}