import './Highlight.sass'
import api from '../api'
export function Highlight(props) {
    return <div className={'col-sm-12 col-lg-3'}  >
        <div className={"highlight"}>
            <img src={api.resToUrl(props.src,'sm')} alt={""}/>
            <div>
            <h2>{props.title}</h2>
            <p>{props.subText}</p>
            </div>

        </div>
    </div>;
}