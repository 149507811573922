import './TextResizer.sass'
import {useEffect, useRef, useState} from "react";

const options = [
    8,
    10,
    11,
    12,
    14,
    16,
    18,
    20,
    24,
    28,
    32,
    36,
    40,
    44,
    48,
    54,
    60,
    66,
    72,
    80,
    88,
    96
]

export const TextResizer = ({sliderValue, setValue, theme}) => {
    const [selected, setSelected] = useState('24')
    const [isOpen, setIsOpen] = useState(false)

    const onDdClick=()=>{
        if(isOpen)
            setIsOpen(false)
        else
            setIsOpen(true);

    }

    useEffect(() => {
        if (!sliderValue)
            return
        setSelected(sliderValue)
    }, [sliderValue]);

    return <div className={'TextResizer '}>
        <span className={'icon'}>
            <svg xmlns="http://www.w3.org/2000/svg" className={'tIcon'}
                 viewBox="0 0 576 512">
                <path fill={theme === 'dark' ? 'white' : 'black'}
                      d="M64 128V96h64l0 320H96c-17.7 0-32 14.3-32 32s14.3 32 32 32H224c17.7 0 32-14.3 32-32s-14.3-32-32-32H192l0-320h64v32c0 17.7 14.3 32 32 32s32-14.3 32-32V80c0-26.5-21.5-48-48-48H160 48C21.5 32 0 53.5 0 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32zM502.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-64 64c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8h32V352H416c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l64 64c12.5 12.5 32.8 12.5 45.3 0l64-64c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8H512V160h32c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-64-64z"/>
            </svg>
        </span>

        <div className={`dropdown`}
             onClick={onDdClick}
        >
            <span>{selected}pt</span>
            <div className={`dropdownBody  ${isOpen ? 'open' : ''} `}>
                {options.map(o => <div className={'textSizeOption'} key={'txtresize-opt-' + o}

                onClick={(e)=>{
                    e.preventDefault()
                    setSelected(o.toString())
                    setValue(o)
                }}
                >
                    {o}pt
                </div>)}
            </div>

        </div>
        <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16"
             viewBox="0 0 512 512" className={'ddIcon'}
            onClick={onDdClick}
        >
            <path fill={theme === 'dark' ? 'white' : 'black'}
                  d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
        </svg>

    </div>
}