import './Button.sass'

export const Button = ({children, outline = false, className = '', href = null, ...props}) =>
    !href
        ?
        <button
            className={`Button ${outline ? 'outline' : ''} ${className}`} {...props}>
            {children}
        </button>
        : <a href={href} className={`Button ${outline ? 'outline' : ''} ${className}`} {...props}>
            {children}</a>