import './FontLoadingSplash.sass'
import babka from '../../img/icons/skating.webp'
import {useEffect, useState} from "react";
import WebFont from '../../lib/mywebfontloader'
import fontSources from "../../fonts.json";
import api from "../../api";

const fontNames = {...fontSources.googleFonts}

const allFonts = [...new Set([...fontNames.latin, ...fontNames.cyrillic, ...fontNames.hebrew, ...fontNames.arabic])]

const fonts = [{"google": {"families": [...allFonts.map(x => `${x}:400,600,400i,600i`)]}}]

export function FontLoadingSplash({onCustomFontsFetched}) {
    const [areFontsLoaded, setAreFontsLoaded] = useState(false);
    const [loadedFontsNum, setLoadedFontsNum] = useState(0);
    useEffect(() => {
        fonts.forEach((font) => {
            WebFont.load({
                ...font, fontactive: (familyName, fvd) => {
                    setLoadedFontsNum(i=>{
                        setAreFontsLoaded(v => i+1 >= allFonts.length * 4)
                        return i+1})
                },
            });
        });

        api.listFonts().then(fonts=>{
            onCustomFontsFetched(fonts)
            WebFont.load({
                custom:{
                    families:fonts.map(x=>x.fontFamily),
                    urls:[api.fontCssPath()]
                }
            });
        })

    }, []);

    useEffect(() => {
        if(areFontsLoaded)
            document.querySelector('html').removeAttribute('class')
    }, [areFontsLoaded]);

    if (areFontsLoaded ) {
        return <></>
    }

    return <div className={'FontLoadingSplash'}>
        <h1>{'\u00A0'}</h1> {/*this hack makes blur work as expected*/}
        <div>
            <img  src={babka} alt={'loading illustration'}/>
        </div>
    </div>;
}