import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import './ArtistPage.sass'
import api from "../api";
import {PortfolioGallery} from "../components/PortfolioGallery";

import inst from '../../src/img/icons/inst.svg'
import {DesignsGallery} from "../components/DesignsGallery";
import {useTranslation} from "react-i18next";

export const ArtistPage = () => {
    const {artistGuid} = useParams()
    const [artist, setArtist] = useState(null)
    const [activeTab, setActiveTab] = useState('portfolio')
    const {t} = useTranslation('root', {keyPrefix: 'portfolio'})

    useEffect(() => {
        api.artists.get(artistGuid).then(a => setArtist(a))
    }, [artistGuid]);

    if (!artist)
        return <></>

    return <>
        <div className={'container ArtistPage'}>
            <div className={'row justify-content-center mb-lg-3'}>
                <div className={'col-lg-10 col'}>
                    <div className={'row justify-content-center justify-content-md-start'}>
                        <div
                            className={'col-md-auto col-12  ps-md-5 mx-auto mx-md-0 d-flex d-md-block justify-content-center'}>
                            <img src={api.resToUrl(artist.avatarGuid, 'sm')} className={'avatar'} alt={'avatar'}/>
                        </div>
                        <div className={'col px-5 pt-3'}>
                            <div className={'row ps-md-5'}>
                                <div className={'col'}>
                                    <h5 className={'name'}>{artist.name}</h5>
                                    <p className={'bio'}>{artist.bio}</p>
                                </div>
                                <div className={'col-auto'}>
                                    <a href={'https://instagram.com/' + artist.instagram}>
                                        <img src={inst} alt={'inst'} className={'inst'}/>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        <div className={'row tabs'}>
            <div className={'col'}>
                <h5 className={`tab ${activeTab === 'portfolio' ? 'active' : ''}`}
                    onClick={() => setActiveTab('portfolio')}
                >
                    {t('works')}
                </h5>
            </div>
            <div className={'col'}>
                <h5 className={`tab ${activeTab === 'designs' ? 'active' : ''}`}
                    onClick={() => setActiveTab('designs')}
                >
                    {t('designs')}
                </h5>
            </div>
        </div>
        <div style={activeTab === 'designs' ? {height: '0px', overflow: 'hidden'} : {}}>
            <PortfolioGallery artistGuid={artistGuid} showAvatar={false}/>
        </div>
        <div style={activeTab === 'portfolio' ? {height: '0px', overflow: 'hidden'} : {}}>
            <DesignsGallery artistGuid={artistGuid} showAvatar={false}/>
        </div>
        </div>
    </>
}