import {useContext, useEffect, useState, useTransition} from "react";
import {ShopCartContext} from "../../App";
import './CartPage.sass'
import api from "../../api";
import {useTranslation} from "react-i18next";
import {QtyControl} from "./QtyControl";
import {Button} from "../../components/Button";
import {useNavigate} from "react-router-dom";

const CartItem = ({good, qty, size, onQtyChange, missingStocksItem}) => {
    const {t} = useTranslation('root', {keyPrefix: 'shop'});
    const missingQty = missingStocksItem?.qty[size] ?? missingStocksItem?.qty

    const [isShaking, setIsShaking] = useState(false)

    useEffect(() => {
        if(!missingStocksItem)
            return
        setIsShaking(true)
        setTimeout(()=>setIsShaking(false),600)
    }, [missingStocksItem?.guid]);

    return <div className={'row'}>
        <div className={`CartItem col ${isShaking  ? 'shake' : ''}`}>
            <div className={'row'}>
                <div className={'col-auto'}>
                    <img className={'img'} src={api.resToUrl(good.photosIds[0], 'sm')} alt={'photo of the good'}/>
                </div>
                <div
                    className={'col  d-flex flex-column justify-content-between align-items-stretch px-3 px-lg-5 ' +
                        'py-lg-2 py-2'}>
                    <h5 className={'title'}>{good.title}</h5>
                    <div className={'row justify-content-between'}>
                        <span className={'size col-auto'}>
                            {
                                size ? `${t('size')}: ${size.toUpperCase()}` : ''
                            }
                        </span>
                        {missingStocksItem && qty > missingQty?
                            <div className={'col-auto d-flex flex-column justify-content-center missingQty'}>
                            <span>
                                {t('notEnoughStocks').replace('$', missingQty.toString())}
                            </span>
                            </div> :
                            <></>}
                        <span className={'price col-auto'} dir={'ltr'}>
                            {good.price} ₪
                        </span>
                    </div>
                </div>
                <div className={'col-auto d-flex flex-lg-column justify-content-between justify-content-lg-center ' +
                    'align-items-center priceRow pt-3 pt-lg-0 '}>

                    <span className={'priceForQty col-auto ms-2 ms-lg-0'} dir={'ltr'}>
                        <span style={{fontWeight: '300'}}>Σ:</span> {good.price * qty} ₪
                    </span>
                    <div className={'col-auto me-2 ms-lg-2 mt-lg-4'}>
                        <QtyControl value={qty} max={missingQty??good.sizesQty?.[size] ?? good.qty} onChange={(q) => {
                            onQtyChange(good, size, q)
                        }} showCartIcon={false}/>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export const CartPage = () => {

    const {t} = useTranslation('root', {keyPrefix: 'shop'});
    const nav = useNavigate()
    const {cartGoods, setCartGoods} = useContext(ShopCartContext)
    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')

    const [isNameValid, setIsNameValid] = useState(true)
    const [isPhoneValid, setIsPhoneValid] = useState(true)

    const [missingGoods, setMissingGoods] = useState([])

    const onChangeQty = (good, size, qty) => {
        const existing = cartGoods.find(item => item.good.guid === good.guid && item.size === size)
        existing.qty += qty
        if (existing.qty === 0)
            setCartGoods(cartGoods.filter(item => item.qty > 0))
        else
            setCartGoods(cartGoods)
    }

    const onOrderClick = () => {
        let valid = true

        if (name?.length < 2) {
            valid = false
            setIsNameValid(false)
        }
        if (phone.length < 10) {
            valid = false
            setIsPhoneValid(false)
        }

        if (!valid) {
            setTimeout(() => {
                setIsPhoneValid(true)
                setIsNameValid(true)

            }, 600)
            return
        }

        api.shop.makeOrder(
            {
                positions: cartGoods.map(i => ({
                    goodId: i.good.guid,
                    qty: i.qty,
                    size: {[i.size]: i.qty},
                })),
                customerName: name,
                customerPhone: phone
            }).then(
            () => {
                setCartGoods([])
                nav('/shop/order?name=' + encodeURIComponent(name))
            })
            .catch((err) => {
                const {outOfStock} = err.response.data.data;
                setMissingGoods(outOfStock.map(x => ({
                    guid: x.guid,
                    qty: x.qty ?? x.sizesQty,
                })))
            })
    }


    if (cartGoods.length === 0)
        return <div className={'CartPage container'}>
            <h3>{t('cart')}</h3>
            <h3 className={'mt-5'}>{t('empty')}</h3>
        </div>

    return <div className={'CartPage container'}>
        <h3>{t('cart')}</h3>

        {cartGoods.map(i =>
            <CartItem key={'cartitem-' + i.good.guid}
                      missingStocksItem={missingGoods.find(item => item.guid === i.good.guid)}
                      {...i} onQtyChange={onChangeQty}/>)}

        <h3 className={'p-3'} style={{
            'margin-block': 0,
            'margin-inline': 0
        }}>Total: {cartGoods.map(i => i.good.price * i.qty).reduce((a, b) => a + b)} ₪</h3>
        <h6 className={'delivery-text'}>
            {t('deliveryInfo')}
        </h6>
        <div className={'row px-3 justify-content-center py-3'}>
            <div className={'col-auto'}>
                <div className={'nameInput row'}>
                    <div className={'col-lg col-12'}>
                        <input className={!isNameValid ? 'shake invalid' : ''}
                               value={name}
                               onChange={e => setName(e.target.value)} placeholder={t('yourName') + ':'}/>
                    </div>
                    <div className={'col-lg col-12'}>

                        <input className={!isPhoneValid ? 'shake invalid' : ''}
                               value={phone}
                               onChange={e => {
                                   if (/^\+?\d*$/.test(e.target.value)) setPhone(e.target.value)
                               }} placeholder={t('phone') + ':'}/>
                    </div>
                    <div className={'col-lg col-12'}>
                        <Button
                            className={'m-lg-0'}

                            onClick={onOrderClick}
                        >{t('makeOrder')}</Button>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
