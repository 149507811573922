import './FontSelector.sass'
import {useEffect, useState} from "react";
import fontSources from '../../fonts.json'

let customLoaded = false

export const FontSelector = ({value, onChange, theme, customFonts}) => {

    const [isOpen, setIsOpen] = useState(false)
    const [fontNames, setFontNames] = useState({...fontSources.googleFonts})

    const [selectedScript, setSelectedScript] = useState('latin')
    const [testText, setTestText] = useState('')

    const isScriptSelected = (s) => s === selectedScript ? 'selected' : ''

    useEffect(() => {
        if (!customFonts?.length || customLoaded)
            return


        setFontNames(v=>{
            const fn = {...v}
            fn.cyrillic = [...fn.cyrillic,...customFonts.filter(f => f.fonts[0].ru).map(f => f.fontFamily)]
            fn.latin = [...fn.latin,...customFonts.filter(f => f.fonts[0].en).map(f => f.fontFamily)]
            fn.hebrew = [...fn.hebrew,...customFonts.filter(f => f.fonts[0].he).map(f => f.fontFamily)]
            fn.arabic = [...fn.arabic,...customFonts.filter(f => f.fonts[0].ar).map(f => f.fontFamily)]
            return fn
        })
        customLoaded = true;

    }, [customFonts]);

    return <div className={'FontSelector'}>
        <div className={'selector-wrapper'} onClick={() => setIsOpen(v => !v)}>
            <span style={{fontFamily: value}} className={'selectedFont'}>
                {value}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512">
                <path fill={theme === 'dark' ? 'white' : 'black'}
                      d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/>
            </svg>
        </div>
        <div className={`fontsDropdown ${isOpen ? 'open' : ''}`}>
            <div className={'controlRow'}>
                {[
                    ['latin', 'Ab'],
                    ['cyrillic', 'Аб'],
                    ['hebrew', 'אב'],
                    ['arabic', 'اب']
                ].map(([s, e], i) => <div className={'abGroup'} key={`scriptSelector${i}`}>
                        <div onClick={() => setSelectedScript(s)}
                             className={isScriptSelected(s)}>{e}</div>
                    </div>
                )}
            </div>

            <input
                className={'exampleInput'}
                placeholder={{
                    latin: 'The quick brown fox jumps over a lazy dog',
                    cyrillic: 'Съешь еще этих мягких французских булок да выпей чаю',
                    hebrew: 'צפע חזק נשך דג מת באוסטרליה',
                    arabic: "صِفْ ذَا الْبَغْلَ بِجَمَالِهِ، وَخُصْهُ، زَيْدًا، شَكْلَهُ."
                }[selectedScript]}

                onChange={(v) => {
                    setTestText(v.target.value)
                }}
                value={testText}/>


            {fontNames[selectedScript]
                .map((x, i) => <div
                        className={'option'}
                        key={'fonts-dd-option' + i}
                        style={{'fontFamily': x}}
                        onClick={() => {
                            onChange(x);
                            setIsOpen(false)
                        }}
                    >{testText ? testText : x}</div>
                )}

        </div>
    </div>
}