import api from "../api";
import './Artist.sass'
import {useNavigate} from "react-router-dom";
export function Artist(props) {

    let nav = useNavigate()
    return <div
        className={"Artist"} onClick={()=> nav('/artist/'+props.x.guid)}>

        <img src={api.resToUrl(props.x.avatarGuid, "sm")} alt={"portrait"} className={"portrait"}/>
        <h4>{props.x.name}</h4>
        <h5>{props.x.subText}</h5>
    </div>;
}