import './WelcomeSection.sass'
import {Button} from "../Button";

import {Highlight} from "../Highlight";
import {useTranslation}  from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {getBrowserLocale} from "../../l18n/init";
import api from "../../api";

const capitalizeLng = (l)=>({'en':'En','ru':'Ru','he':'He'}[l])

export function WelcomeSection() {
    const navigate = useNavigate()
    const {t} = useTranslation('root',{keyPrefix: 'index.welcome'});

    const lng = capitalizeLng(getBrowserLocale())

    const [highlightsResp, setHighlightsResp] = useState([])
    const [welcome, setWelcome] = useState(null)
    const [whatsapp, setWhatsApp] = useState(null)
    useEffect(() => {
        api.config.welcome().then(w=>setWelcome(w))
        api.config.highlights().then(h=>setHighlightsResp(h))
        api.config.whatsapp().then(h=>setWhatsApp(h))

    }, []);

    let highlights = highlightsResp.map(x=>({
        icon : x.PictureGuid,
        title: x.Title[lng],
        subText: x.Description[lng],
    }))

    let whatsappLink = whatsapp ? (
        `https://wa.me/${whatsapp.Phone}?text=`+encodeURIComponent(whatsapp.MsgText[lng])
    ):'';
    console.log(whatsappLink)
    return <div className={'WelcomeSection'} dir={lng === 'He' ? 'rtl' : 'ltr'}>
        <div className={'wrapper'}>
            <div className={'welcome-first'}>
                <div className={'container'} style={{'height': '100%'}}>
                    <div className={'first-section-layout'}>
                        <h1 className={'px-2'}>{welcome?.Title[lng] ?? ''}</h1>
                        <p className={'px-2'}>
                            {welcome?.SubText[lng] ?? ''}
                        </p>
                        <div className={'buttons px-2'}>
                            <Button outline={true} onClick={()=>{navigate('/fonts')}}>{t('btnFonts')}</Button>
                            <Button className={'mx-lg-3'} outline={true} onClick={()=>{navigate('/shop')}}>{t('shop')}</Button>
                            <Button className={'my-1 my-md-0'} href={whatsappLink}>{t('btnBooking')}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={'welcome-icons'}>
                <h3>{t('whyUs')}</h3>
                <div className={'container'}>
                    <div className={'row px-3'}>
                        {
                            highlights.map(({icon, title, subText},i) =>
                                <Highlight src={icon} title={title} subText={subText} key={`hlt${i}`}/>)
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>

}