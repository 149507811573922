import './PortfoliosSection.sass'
import {PortfolioGallery} from "../PortfolioGallery";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {DesignsGallery} from "../DesignsGallery";


export const PortfoliosSection = () => {

    const {t} = useTranslation('root', {keyPrefix: 'portfolio'})

    const [activeTab, setActiveTab] = useState('portfolio')


    return <div className={"PortfoliosSection"}>
        <h3>{t('title')}</h3>
        <div className={'row tabs'}>
            <div className={'col'}>
                <h5 className={`tab ${activeTab === 'portfolio' ? 'active' : ''}`}
                    onClick={()=>setActiveTab('portfolio')}
                >
                    {t('works')}
                </h5>
            </div>
            <div className={'col'}>
                <h5 className={`tab ${activeTab === 'designs' ? 'active' : ''}`}
                    onClick={()=>setActiveTab('designs')}
                >
                    {t('designs')}
                </h5>
            </div>
        </div>
        <div style={activeTab === 'designs' ? {height: '0px', overflow: 'hidden'} : {}}>
            <PortfolioGallery artistGuid={'*'} showAvatar={true}/>
        </div>
        <div style={activeTab === 'portfolio' ? {height: '0px', overflow: 'hidden'} : {}}>
            <DesignsGallery artistGuid={'*'} showAvatar={true}/>
        </div>
    </div>;
};
