import './FooterSection.sass'
import mapIcon from '../../img/icons/mappin.svg'
import instIcon from '../../img/icons/inst.svg'
import phoneIcon from '../../img/icons/tel.svg'
import {useEffect, useState} from "react";
import api from "../../api";
import {BusinessDays} from "../BusinessDays";
import {useTranslation} from "react-i18next";
import {getBrowserLocale} from "../../l18n/init";
const capitalizeLng = (l)=>({'en':'En','ru':'Ru','he':'He'}[l])

export function FooterSection() {
    const {t} = useTranslation('root',{keyPrefix: 'footer'})
    const lang = getBrowserLocale()
    const [inst, setInst] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState(null)
    const [workingHoursRep, setWorkingHoursRep] = useState()

    const lng = capitalizeLng(getBrowserLocale())

    const lines = [
        [mapIcon, address? address[lng]:'', null],
        [instIcon, `@${inst}`, `https://instagram.com/${inst}`],
        [phoneIcon, phone, `tel:${phone.replace(' ', '')}`],
    ]

    const getDayBar = (d)=>d.From === 0 && d.To === 0 ? null :[d.From,d.To]

    const bars =  workingHoursRep ? {
        'SU':getDayBar(workingHoursRep.Su),
        'MO':getDayBar(workingHoursRep.Mo),
        'TU':getDayBar(workingHoursRep.Tu),
        'WE':getDayBar(workingHoursRep.We),
        'TH':getDayBar(workingHoursRep.Th),
        'FR':getDayBar(workingHoursRep.Fr),
        'SA':getDayBar(workingHoursRep.Sa),
    }:null

    useEffect(() => {
        api.config.contacts().then(x => {
            setInst(x.Instagram)
            setPhone(x.Phone)
            setAddress(x.Address)
        })
        api.config.workingHours().then(x => {
            setWorkingHoursRep(x)
        })
    }, []);

    return <div className={'FooterSection'}>
        <div className={'row'}>
            <div className={'col-lg-6 col-12'}>
                <div className={'row justify-content-center'}>
                    <div className={'col-12 col-lg-8'}>
                        <div className={'contacts'}>

                            <h3>{t('title')}</h3>

                            <div className={'contacts-data'}>
                                {lines.map(([icon, text, href], i) =>
                                    <div className={'row px-lg-3 px-2 justify-content-center align-items-center my-3'} key={`fline-${i}`}>
                                        <div className={'col-auto'}>
                                            <img src={icon} alt={'icon'}/>
                                        </div>
                                        <div className={'col-lg-8 col-9 px-3'} dir={text.trim()[0]==='+' || text.trim()[0]==='@' ? 'ltr':null}>
                                            <a href={href}>{text}</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={'working-hours'}>
                        {bars?<BusinessDays bars={bars}/>:<></>}

                    </div>

                </div>
            </div>
            <div className={'col-lg-6 col-12'}>
                <iframe title={'map'}
                        src="https://yandex.ru/map-widget/v1/?um=constructor%3A5aee256c782d35f88fdecdbf1476317f37f37089afcdafdfcf5a837b44a59d2c&amp;source=constructor"
                        width="100%" height="700"
                        style={{marginBottom: '-5px', 'border': "none"}}></iframe>
            </div>
        </div>
    </div>;
}