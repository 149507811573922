import cartIcon from '../../img/icons/cart.svg'
import {useNavigate} from "react-router-dom";
import {useContext, useState} from "react";
import {ShopCartContext} from "../../App";
import {getBrowserLocale} from "../../l18n/init";

export const CartBtn = () => {
    const nav = useNavigate()
    const {cartGoods} = useContext(ShopCartContext)
    const lng = getBrowserLocale()
    return <div style={{
        position: 'fixed', top: 100,
        left: lng === 'he'?30:'unset',
        right: lng === 'he' ? 'unset' : 30,
        cursor: 'pointer',
        maxWidth: 80,
        justifyContent: 'end',
        zIndex:999
    }}

                className={'d-flex'}
    onClick={()=>nav('/shop/cart')}>
        <div>
        <span
            style={{
                'borderRadius': '50px',
                background: '#BB73BF',
                fontWeight: 'bold',
                color: 'white', fontSize: 20, display: 'block', padding: '3px 10px',
                margin: '0 7.5px',
                textAlign:'center'
            }}
        >{cartGoods.length}</span>
        </div>
        <img src={cartIcon} alt={'cart'}/>
    </div>
}