import api from '../../api'
import './Portfolio.sass'
import {useNavigate} from "react-router-dom";
export const Portfolio = ({resGuid,artist,showAvatar,onPhotoClick}) => {
    const nav = useNavigate();
    return <div className={"Portfolio"}>
        <img className={"photo"} alt={'portfolio pic'} src={api.resToUrl(resGuid,'lg')}
            onClick={onPhotoClick}

        />
        {showAvatar ?
            <img className={'artist'} alt={artist.name} src={api.resToUrl(artist.avatarGuid, 'sm')}
                onClick={()=>nav('/artist/'+artist.guid)}
            />
            :<></>
        }
    </div>
}