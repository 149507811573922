import axios from 'axios'

const API_BASE = process.env.REACT_APP_API_BASE

export default {
    portfolios: {
        list: async (artistGuid, offset) => {
            const resp = await axios.get(`${API_BASE}/artists/${artistGuid}/portfolio?offset=${offset}`)
            return resp.data
        },
    },
    designs:{
        list: async (artistGuid, offset) => {
            const resp = await axios.get(`${API_BASE}/artists/${artistGuid}/designs?offset=${offset}`)
            return resp.data
        },
    },
    fontCssPath: () => `${API_BASE}/fonts.css`,
    resToUrl: (guid, size) => `${API_BASE}/resources/${guid}-${size}`,

    artists: {
        list: async () => {
            const resp = await axios.get(`${API_BASE}/artists`)
            return resp.data
        },
        get: async (artistGuid,) => {
            const resp = await axios.get(`${API_BASE}/artists/${artistGuid}`)
            return resp.data
        }
    },
    config: {
        workingHours: async () => {
            const resp = await axios.get(`${API_BASE}/config/working-hours`)
            return resp.data
        },
        contacts: async () => {
            const resp = await axios.get(`${API_BASE}/config/contacts`)
            return resp.data
        },
        welcome: async () => {
            const resp = await axios.get(`${API_BASE}/config/welcome`)
            return resp.data
        },
        highlights: async () => {
            const resp = await axios.get(`${API_BASE}/config/highlights`)
            return resp.data
        },
        whatsapp: async () => {
            const resp = await axios.get(`${API_BASE}/config/whatsapp`)
            return resp.data
        }
    },
    listFonts: async () => {
        const resp = await axios.get(`${API_BASE}/fonts`)
        return resp.data
    },

    shop: {
        listCategories: async () => {
            const resp = await axios.get(`${API_BASE}/shop/categories`)
            return resp.data
        },
        getCategoryWithGoods: async (key) => {
            const resp = await axios.get(`${API_BASE}/shop/categories/${key}:with-goods`)
            return resp.data
        },
        listGoods: async (categoryKey) => {
            const resp = await axios.get(`${API_BASE}/shop/categories/${categoryKey}`)
            return resp.data
        },
        getGood : async(goodId)=>{
            const resp = await axios.get(`${API_BASE}/shop/categories/*/${goodId}`)
            return resp.data
        },
        makeOrder:async(order)=>{
            const resp = await axios.post(`${API_BASE}/shop/orders`,order)
            return resp.data
        }


    }
}