import babka from '../../img/orderPageImg.png'
import './OrderPage.sass'
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

export const OrderPage = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const {t} = useTranslation('root', {keyPrefix: 'shop'});
    const name = urlParams.get('name');
    const nav = useNavigate()
    return <div
        className={'OrderPage'}
    >
        <div className={'row justify-content-center'}>
            <div className={'col-auto'}>
                <img src={babka} alt={'illustration'}/>
            </div>
        </div>

        <h4><b>{name},</b> {t('thanksForOrder')}</h4>
        <h4>
            {t('weAreWaiting')}
            <b className={'contacts-link'}
               onClick={()=>nav('/#footer')}

            >{t('atTheStudio')}</b>
            {t('toCollectOrder')}
        </h4>
    </div>
}