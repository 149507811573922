import {useContext} from "react";
import {PortfolioModalContext} from "../App";
import {LightboxModal} from "./LightboxModal";


export const PortfolioModal = () => {

    const {modalPortfolios, setModalPortfolios} = useContext(PortfolioModalContext)

    const onBackdropClick = ()=>setModalPortfolios([])

    if (modalPortfolios.length === 0)
        return <></>
    return <LightboxModal imageGuids={modalPortfolios.map(p=>p.guid)} onBackdropClick={onBackdropClick}/>
}

