import './ArtistsSection.sass'
import {useEffect, useState} from "react";
import api from "../../api";
import {Artist} from "../Artist";
import {useTranslation} from "react-i18next";

Object.defineProperty(Array.prototype, 'chunk', {
    value: function (chunkSize) {
        const R = [];
        for (let i = 0; i < this.length; i += chunkSize)
            R.push(this.slice(i, i + chunkSize));
        return R;
    }
});

const makeRows = (artists, maxPerRow) => {
    let i = 0;
    let rows = []
    for (let artist of artists) {
        if (!rows[i])
            rows.push([])
        const row = rows[i]
        row.push(artist)
        if (row.length === maxPerRow)
            i += 1
    }
    return rows
}

export function ArtistsSection() {
    const [artists, setArtists] = useState([])
    const {t} = useTranslation('root', {keyPrefix: 'artists'});
    useEffect(() => {
        api.artists.list().then(x => setArtists(x))
    }, []);

    let maxPerRow;
    if (artists.length >= 6) {
        if (artists.length >= 9) {
            if (artists.length >= 12) {
                maxPerRow = 6;
            } else {
                maxPerRow = 5;
            }
        } else {
            maxPerRow = 3;
        }
    } else {
        maxPerRow = 6;
    }

    return <div className={'ArtistsSection'}>
        <h3>{t('outArtists')}</h3>
        <div className={'container desktop-show'}>
            <div className={'row justify-content-center'}>
                <div className={'col-auto artists-container'}>
                    {[...artists,].map(x => <Artist x={x} key={`${x.guid}dsktp`}/>)}
                </div>
            </div>
        </div>
        <div className={'mobile-show'}>
            <div className={'artists-container'}>
                {
                    makeRows(artists, maxPerRow)
                        .map((chunk, i) => <div className={'mobile-row'} key={`chunk-${i}`}>
                                {chunk.map(x => <div className={'artist-wrapper'} key={`${x.guid}m`}>
                                    <Artist x={x}/>
                                </div>)}
                            </div>
                        )
                }
            </div>
        </div>
    </div>
}