import './bootstrapImports.sass'
import './App.sass';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {IndexPage} from "./pages/IndexPage";
import {Header} from "./components/Header";
import {ArtistPage} from "./pages/ArtistPage";
import {createContext, useState} from "react";
import {PortfolioModal} from "./components/PortfolioModal";
import {FontsPage} from "./pages/FontsPage";
import './l18n/init'
import {HealingPage} from "./pages/HealingPage";
import {ShopPage} from "./pages/shop/ShopPage";
import {CartPage} from "./pages/shop/CartPage";
import {GoodModal} from "./pages/shop/GoodModal";
import {OrderPage} from "./pages/shop/OrderPage";
import {CategoryPage} from "./pages/shop/CategoryPage";

export const PortfolioModalContext = createContext({
    modalPortfolios: [],
    setModalPortfolios: (p) => {
    }
})

export const GoodModalContext = createContext({
    good: null,
    setGood: (p) => {
    }
})
export const ShopCartContext = createContext({
    cartGoods: [],
    setCartGoods: (p) => {    }
})


function App() {

    const [modalPortfolios, setModalPortfolios] = useState([])
    const [modalGood, setModalGood] = useState(null)
    const [cartGoods, setCartGoods] = useState(JSON.parse(localStorage.getItem('cart') ?? '[]'))
    if(!Array.isArray(cartGoods))setCartGoods([])
    return <BrowserRouter>
        <Header/>
        <PortfolioModalContext.Provider value={{modalPortfolios, setModalPortfolios}}>
            <Routes>
                <Route path={'/'} element={<IndexPage/>}/>
                <Route path={'/fonts'} element={<FontsPage/>}/>
                <Route path={'/healing'} element={<HealingPage/>}/>
                <Route path={'/shop/*'}  element={
                    <GoodModalContext.Provider value={{good: modalGood, setGood: setModalGood}}>
                        <ShopCartContext.Provider value={{cartGoods: cartGoods, setCartGoods: (v)=>{
                            localStorage.setItem('cart',JSON.stringify(v))
                            setCartGoods([...v])
                        }}}>
                            <Routes>
                                <Route path={'/cart'} element={<CartPage/>}/>
                                <Route path={'/category/:catId'} element={<CategoryPage/>}/>
                                <Route path={'/order'} element={<OrderPage/>}/>
                                <Route path={'/:goodId'} element={<ShopPage/>}/>
                                <Route path={'/'} element={<ShopPage/>}/>
                            </Routes>
                            <GoodModal/>
                        </ShopCartContext.Provider>
                    </GoodModalContext.Provider>}/>
                <Route path={'/artist/:artistGuid'} element={<ArtistPage/>}/>
            </Routes>
            <PortfolioModal/>
        </PortfolioModalContext.Provider>
    </BrowserRouter>
}

export default App;
