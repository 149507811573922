import logoSm from '../img/logo-sm.webp'
import './Header.sass'
import {LangSelector} from "./LangSelector";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {MobileHeader} from "./MobileHeader";

let scrollSpyTargets = {}

const targetToClassName = {
    'welcome': 'WelcomeSection',
    'artists': 'ArtistsSection',
    'portfolio': 'PortfoliosSection',
    'footer': 'FooterSection',
}
let instantScroll = false

export function Header() {
    const [target, setTarget] = useState('welcome')
    const nav = useNavigate()
    const {t} = useTranslation('root', {keyPrefix: 'header'})
    const [showDropdown, setShowDropdown] = useState()
    const [dropdownPosition, setDropdownPosition] = useState({top: 0, left: 0})

    const options = {
        'welcome': t('options.welcome'),
        'artists': t('options.artists'),
        'portfolio': t('options.portfolio'),
        'footer': t('options.footer'),
    }

    const dropDown = useRef()
    const onResize = () => {
        scrollSpyTargets = {}

        for (const [key, cl] of Object.entries(targetToClassName)) {
            let el = document.querySelector(`div.${cl}`)
            if (!el)
                return
            scrollSpyTargets[key] = el.offsetTop + el.clientHeight - window.innerHeight / 2
        }
    };
    const onClickAnywhere = () => setShowDropdown(false)
    const onScroll = () => {
        onResize()
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop

        let targets = Object.entries(scrollSpyTargets).map(([key, target]) => {
            return [key, target - scrollTop]
        }).toSorted((a, b) => a[1] - b[1])

        if (!targets && !targets[0]) {
            return
        }

        if (targets.length !== 0) {
            targets = targets.filter(x => x[1] > 50)
        }
        if (targets.length === 0) {
            return;
        }

        const f = targets[0][0]
        setTarget(f)
    }

    useEffect(() => {
        window.addEventListener('click', onClickAnywhere)
        document.addEventListener('resize', onResize)
        document.addEventListener('scroll', onScroll)
        onScroll()
        return () => {
            document.removeEventListener('resize', onResize);
            document.removeEventListener('scroll', onScroll);
            window.removeEventListener('click', onClickAnywhere)
        }
    }, []);

    useEffect(() => {
        if (!window.location.hash)
            return
        let timeout = instantScroll ? 0 : 750
        instantScroll = false
        setTimeout(() => {
            const q = window.location.hash.replace('#', '');
            const cls = targetToClassName[q]
            const element = document.querySelector(`div.${cls}`);
            const topPos = element.offsetTop;
            console.log('cls', cls)
            window.scrollTo({
                top: topPos,
                behavior: 'smooth'
            });
        }, timeout)


    }, [window.location.hash])

    const onClick = (t) => {
        instantScroll = true
        nav('/#' + t)
    }
    const onClickMobile = (t) => {
        if(t==='healing') {nav('/'+t);return}
        if(t==='fonts') {nav('/'+t);return}
        if(t==='shop') {nav('/'+t);return}
        onClick(t)
    }
    const isFontsPage = window.location.pathname.endsWith('/fonts')
    const isHealingPage = window.location.pathname.endsWith('/healing')
    const isShopPage = window.location.pathname.startsWith('/shop')
    const isIndexPage = !(isFontsPage || isShopPage || isHealingPage)

    return <nav className={'Header'}>
        <div className={'container'}>
            <div className={'content'}>

                <span className={'logo'}>
                    <img src={logoSm} alt={'logo'} onClick={() => nav('/')}/>
                </span>

                <div className={'d-flex flex-row show-desktop'}>
                    <span className={`justify-content-center indexNav ${isIndexPage ? 'active' : ''}`}

                          onClick={(e) => {
                              e.stopPropagation()

                              if (!isIndexPage) {
                                  nav('/')
                                  setTarget('welcome')
                                  return
                              }

                              setShowDropdown(true);
                              const rect = e.target.getBoundingClientRect()

                              dropDown.current.style.display = 'block' // makes rect working properly
                              const ddRect = dropDown.current.getBoundingClientRect()
                              dropDown.current.style.display = ''

                              const leftJustifier = (ddRect.right - ddRect.left - (rect.right - rect.left)) / 2
                              setDropdownPosition({left: rect.left - leftJustifier, top: rect.bottom - 3})
                          }}>{isIndexPage ? options[target] : options['welcome']}</span>

                    <ul className={`dropdown ${showDropdown ? 'show' : ''}`} ref={dropDown}
                        style={{...dropdownPosition}}>
                        {Object.entries(options).map(([k, v], i) =>
                            <li key={'mobHeaderLi' + i} onClick={() => onClick(k)}>{v}</li>)}
                    </ul>

                    <ul className={'show-desktop'}>
                        <li className={'ms-2'}>
                            <Link to={'/healing'}
                                  className={isHealingPage ? 'active' : ''}>{t('options.healing')}</Link>
                        </li>
                        <li>
                            <Link to={'/fonts'} className={isFontsPage ? 'active' : ''}>{t('options.fonts')}</Link>
                        </li>
                        <li>
                            <Link to={'/shop'} className={isShopPage ? 'active' : ''}>{t('options.shop')}</Link>
                        </li>
                    </ul>
                </div>

                <MobileHeader target={target} onClick={onClickMobile}/>
                <LangSelector/>
            </div>
        </div>
    </nav>
}